.cpaToolsBtns {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px;
}
.cpaToolsBtnImg {
    height: 20px;
    width: 100%;
}
.cpaToolsCard {
    margin-top: 10px;
}
.categoriesTitle {
    padding-left: 8px;
    margin-bottom: 0px;
}
.cpaToolsTitle {
    color: #495057DE;
    font-size: 26px;
    font-family: Montserrat-SemiBold;
    text-transform: capitalize;
    padding-bottom: 20px;
}
.cpaToolsTopHeading {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0rem;
    flex-wrap: wrap;
}
.cpaToolsTitleBlue {
    color: #045490 !important;
}
.cpaToolsTabs .p-tabview-title {
    width: 100%;
    font-size: 16px;
    font-family: Montserrat-SemiBold;
}
.cpaToolsTabs .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
    background: #EEEEEE;
    color: #495057DE;
    box-shadow: 0px 3px 6px #00000029;
}
.cpaToolsCard .p-card-shadow .p-card-header img {
    height: 100%;
    padding: 25px;
    width: 100%;
}
.cpaToolsCard .p-input-icon-right > i:last-of-type {
    position: absolute;
    top: 1.5rem;
}
.cpaToolsCard .p-card {
    background: #EEEEEE;
    width: 100%;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 3px;
}
.cpaToolsCard .p-card .p-card-content {
    padding: 0px;
}

.cpaToolsCard .p-card .p-card-title {
    font-weight: 0 !important;
    font-size: 16px;
    text-align: left;
    font-weight: bold;
    color: #888888;
    font-family: Montserrat-Bold;
    margin-left: 12px;
  }
  .cpaTitleAndSearch {
      display: flex;
      justify-content: space-between;
  }
  .cpaToolsSearch {
      width: 100%;
      margin-bottom: 15px;
      padding-bottom: 10px;
  }
  .cpaToolsRightContent .p-col-12 .p-md-6 {
    box-shadow: 0px 3px 6px #00000029;
  }
  .cpaToolsIconsBtn {
      display: flex;
  }
.cpaToolsBtnBlue .p-button .pi-exclamation-circle:before {
    font-size: 20px;
}
  @media screen and (min-width:377px) and (max-width: 545px) {
    .cpaToolsBtns {
        justify-content: center;
    }
  }
  @media screen and (min-width:1301px) and (max-width: 1525px) {
    .cpaToolsBtns {
        justify-content: center;
    }
    .cpaToolsIconsBtn {
        margin-top: 10px;
    }
  }

  @media screen and (max-width: 376px) {
    .cpaToolsBtns {
        justify-content: center;
    }
  }
  @media screen and (max-width: 469px) {
    .cpaTitleAndSearch {
        justify-content: center;
        flex-wrap: wrap;
    }
    .cpaToolsTopHeading {
        justify-content: center;
    }
    .selectedItems {
        font-size: 24px;
    }
    .cpaToolsTitle {
        justify-content: center;
    }
  }
  @media screen and (max-width: 546px) {
    .cpaToolsTitle {
       display: flex;
    align-self: baseline;
    padding-bottom: 0px;
    margin-bottom: 0px;
    }
  }
  

@media (min-width: 992px) {
.p-lg-3.leftTabMenu {
    padding: 1rem 0rem !important;
}
}
  @media screen and (min-width:768px) and (max-width: 957px) {
    .cpaToolExBtn .p-button {
        // width: 162px!important;
    }
    .cpaToolsIconsBtn {
        margin-top: 10px;
    }
    .cpaToolsBtns {
        justify-content: center;
    }
  }

  @media screen and (min-width:992px) and (max-width: 1075px) {
    // .cpaToolsBtns {
    //     justify-content: center;
    // }
    .cpaToolsIconsBtn {
        margin-top: 10px;
    }
  }

  @media screen and (min-width:992px) and (max-width: 1099px) {
    .cpaToolsCard .p-card {
        margin-left: 20px;
    }
    .cpaToolsPaddingLR.paddingLeftRight {
        padding: 0px 20px 0px 0px !important;
    }
    .cpaToolsRightContent {
        margin-left: 0.5rem;
    }
    .cpaTitleAndSearch {
        margin-left: 2.5rem;
    }
    .cpaToolsBtns {
        justify-content: center;
    }
    .cpaToolsIconsBtn {
        margin-top: 10px;
    }    
  }
  @media screen and (min-width:1100px) and (max-width: 1300px) {
    .cpaToolsRightContent {
        margin-left: 3rem;
    }
    .cpaTitleAndSearch {
        margin-left: 3.5rem;
    }
    .cpaToolsBtns {
        justify-content: center;
    }
    .cpaToolsIconsBtn {
        margin-top: 10px;
    }
  }
  @media screen and (min-width: 1600px) {
    .cpaTitleAndSearch .p-input-icon-right {
       width: 480px;
    }
  }