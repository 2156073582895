@font-face {
  font-family: 'Montserrat-Regular';
  src: url('fonts/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('fonts/Montserrat-SemiBold.ttf');
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: url('fonts/Montserrat-Medium.ttf');
}

@font-face {
  font-family: 'Montserrat-Light';
  src: url('fonts/Montserrat-Light.ttf');
}

@font-face {
  font-family: 'Montserrat-Black';
  src: url('fonts/Montserrat-Black.ttf');
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('fonts/Montserrat-Bold.ttf');
}
