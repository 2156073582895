body {
  overflow-y: scroll !important;
}

.bodyChartWrapper {
  height: 100vh;
  padding-bottom: 50px;
  box-shadow: 0px 2px 26px #272d3b1a;
  
}
.bodyChartImagesWrapper {
  height: 100%;
  padding-bottom: 50px;
  box-shadow: 0px 2px 26px #272d3b1a;
}
.bodyChartPainterro, .sideImgs .p-grid  {
  height: 100%;
}
.painterroClass .ptro-wrapper canvas  {
  max-width: 100% !important;
  max-height: 100% !important;
}
.extractedImg img {
  height: 200px;
  width: 100%;
}
.painterroClass {
  width: 100%;
  height: 100%;
  z-index: 0;
  position: relative;
  top: 0px;
}
.imgUploadBodyChartTitle {
  // font-size: 26px;
  font-size: 20px;   //hamza
    margin-top: 28px;   //hamza
  letter-spacing: 0px;
color: #045490;
text-transform: capitalize;
  font-family: Montserrat-SemiBold;
  margin-bottom: 16px;
}
.bodyChartPainterro .ptro-wrapper {
  background-color: #fff;
}
// .sideImgs {
//   padding: 5px 20px !important;
//   margin-bottom: 30px;
// }
.bodyChartPainterro .ptro-bar,
.bodyChartPainterro .ptro-wrapper {
  width: 100%;
}
.bodyChartPadding {
  padding: 5px;
}
.bodyChartPainterro .ptro-color-main {
  background: transparent;
}
.bodyChartPainterro .ptro-color-control {
  background-color: #EEEEEE;
  color: #000000;
  box-shadow: 0px 3px 6px #00000029;
  margin: 5px !important;
}
.extractedImg {
  position: relative;
}
.extractedImg .redBtnBodyChart .p-button, .extractedImg .blueBtnBodyChart .p-button {
  position: absolute;
  top: 70%;
}
.extractedImg .redBtnBodyChart .p-button {
  background: #F13131 !important;
  border: 3px solid #F13131;
  left: 72%;
  margin-left: 10px;
}
.extractedImg .redBtnBodyChart .p-button:hover {
  box-shadow: 0px 2px 10px #f13131;
}

.extractedImg .blueBtnBodyChart .p-button {
  left: 60%;
  background: #0274B5 !important;
  border: 1px solid #0274B5;
}
.extractedImg .blueBtnBodyChart .p-button:hover {
  box-shadow: 0px 2px 10px #0274B5;
}
.redBtnBodyChart .p-button, .blueBtnBodyChart .p-button {
  width: 40px !important;
  height: 40px;
  margin-bottom: 2px;
  
}
.redBtnBodyChart .p-button {
  margin-right: 0px !important;
}
///////////////
.rightImagesOne {
  display: none;
}
.redBtnBodyChart1 .p-button, .blueBtnBodyChart1 .p-button {
  width: 40px !important;
  height: 40px;
  margin-bottom: 2px;
}
.redBtnBodyChart1 .p-button {
  background: #F13131 !important;
  border: 3px solid #F13131;
}
.blueBtnBodyChart1 .p-button {
  background: #0274B5 !important;
  border: 1px solid #0274B5;
  margin-right: 10px;
}
.redBtnBodyChart .p-button {
  margin-right: 0px !important;
} 
.iconsDiv {
  display: flex;
  margin: 10px 0px 0px 0px;
  justify-content: flex-end;
}
.uploadImgBtns {
  position: relative;
  bottom: 10px;
  width: 100%;
  justify-content: flex-end;
}
.uploadImgBtns .p-button {
  width: 137px;
}
@media screen and (max-width:1199px) {
  .rightImagesOne {
    display: flex;
  }
  .sideImgs {
    box-shadow: 0px 2px 26px #272d3b1a;
  }
  .rightImagesTwo {
    display: none;
  }
  .redBtnBodyChart .p-button {
    margin-right: 0px !important;
  }
}
@media screen and (min-width:1200px) and (max-width:1500px) {
  .extractedImg .redBtnBodyChart .p-button {
    left: 66% !important;
  }
  .extractedImg .blueBtnBodyChart .p-button {
    left: 46% !important;
  }
}

/////////////  Body Chart Page /////////////////////
//contentWrapperClass
.ContentWrapperClass {
  padding: 0px 100px;
}
//paintrerro
 .painterroClass {
  width: 100%;
  height: 100%;
  z-index: 0;
  position: relative;
  top: 0px;
} 
 .bodyChartPainterro .ptro-bar,
 .bodyChartPainterro .ptro-wrapper {
  width: 100%;
}
//dropdown 
 .painterroDropdown .p-dropdown{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #CED4DA;
  opacity: 1;
}
 .painterroDropdown .p-dropdown:not(.p-disabled):hover {
  border: 1px solid #CED4DA;
 }
  .painterroDropdown .p-dropdown:not(.p-disabled).p-focus{
 box-shadow: none;
 }
 .painterroDropdown .p-dropdown .p-dropdown-label {
  font-family: 'Montserrat-Regular';
  font-size: 14px;
  color:#495057DE;
}
//painterro card
 .painterroCard .p-card {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 26px #272D3B1A;
  opacity: 1;
}  
 .painterroCard .header p{
 font-size: 20px;
 font-family: 'Montserrat-SemiBold';
 color:#045490;
}
//painterro components
 .painterroClass .ptro-bar > div{
  background: #FFFFFF;
}
 .painterroClass .ptro-color-control {
background: #EEEEEE 0% 0% no-repeat padding-box;
box-shadow: none ;
border-radius: 5px;
opacity: 1;
color:#808080;
}
 .painterroClass .ptro-color-control:hover:not(.ptro-color-active-control):not([disabled]) {
background: #EEEEEE 0% 0% no-repeat padding-box;
color: #808080;
}
 .painterroClass .ptro-color-main {
background-color: #EEEEEE;
color: #808080;
}


 .addBtnpadd{
  padding: 0px !important;
}
 .addBtn .p-button {
  color: #ffffff;
  border: 1px solid #0263AC;
  background: #0263AC 0% 0% no-repeat padding-box;
  border-radius: 3px;
  padding: 6px 6px;
  opacity: 1;
  font-family: 'Montserrat-Regular';
}
 .addBtn .p-button-label {
color: #FFFFFF;
opacity: 1;
font-family: 'Montserrat-Regular';
}
 .addBtn .p-button:enabled:hover {
  background: #0263AC;
  color: #ffffff;
  border-color: #0263AC;
}
 .addBtn .p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0 #0263AC;
}
 .btnGroup{
margin-top: 45px;
}
 .painterroBtn .p-button {
  color: #ffffff;
  border: 1px solid #0263AC;
  background: #0263AC 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
  margin-right: 14px;
  width: 164px;
  font-family: 'Montserrat-Regular';
  padding: 3px 3px;
}
 .painterroBtn .p-button-label {
color: #FFFFFF;
opacity: 1;
font-family: 'Montserrat-Regular';
}
 .disableBtnColor .p-button {
  background: #71A7D0 0% 0% no-repeat padding-box;
  border:1px solid #71A7D0;
  border-radius: 3px;
}
 .painterroBtn .p-button:enabled:hover {
  background: #0263AC;
  color: #ffffff;
  border-color: #0263AC;
}
 .painterroBtn .p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0 #0263AC;
}

@media screen and (min-width: 320px) and (max-width:424px) {
 .painterroClass .ptro-wrapper .ptro-settings-widget {
  width: 224px;
  padding: 5px 2px 5px;
}
 .painterroClass td.ptro-resize-table-left {
text-align: left;
padding-right: 5px;
float: none;
font-size: 12px;
}
.bodychart-ui .container, .bodychart-ui .body-chart-summary {
  padding: 0;
}
.bodychart-ui .body-chart-summary {
  padding-top: 10px;
}
}
@media screen and (min-width: 320px) and (max-width:767px) {
  .ContentWrapperClass {
      padding: 0px 0px;
    }
     .painterroBtn .p-button {
      margin: 5px;
    }
     .btnGroup{
        text-align: center;
    }
     .addBtn .p-button {
     width:164px;
  }
   .addBtnpadd{
      text-align: center;
  }
}
@media screen and (min-width: 768px) and (max-width:850px) {
   .painterroBtn .p-button {
      margin-right: 12px;
      width: 146px;
  }
} 

// Painterro


@media screen and (min-width: 320px) and (max-width:992px) {
   .bodyChartPainterro .ptro-paster-select .ptro-in {
     padding: 8px;
     border-radius: 5px;
 }
   .bodyChartPainterro .ptro-paster-wrappers-fits {
     flex-direction: column;
 }
   .bodyChartPainterro .ptro-selector-btn i {
     font-size: 38px;
 }
   .bodyChartPainterro .ptro-paster-select .ptro-in div {
     font-size: 14px;
 }
   .bodyChartPainterro .ptro-paster-select-wrapper-extends .ptro-paster-wrapper-label[class] {
     margin-top: 4px;
 }
   .bodyChartPainterro .ptro-paster-fit {
     margin-right: 0px;
 }
   .bodyChartPainterro .ptro-selector-fit {
     height: 100px;
     width: 115px;
 }
   .bodyChartPainterro .ptro-paster-fit .ptro-paster-wrapper-label {
     font-size: 13px;
     margin: 4px 0px;
 }
   .bodyChartPainterro .ptro-selector-extend {
     height: 45px;
     width: 45px;
 }
   .bodyChartPainterro .ptro-icon-paste_extend_top:before {
     font-size: 27px;
 }
   .bodyChartPainterro .ptro-icon-paste_extend_left:before {
     font-size: 27px;
 }
   .bodyChartPainterro .ptro-icon-paste_extend_right:before {
     font-size: 27px;
 }
   .bodyChartPainterro .ptro-icon-paste_extend_down:before {
     font-size: 27px;
 }
}

@media screen and (min-width: 992px) and (max-width:1086px) {
   .painterroClass .ptro-paster-select-wrapper-extends button:nth-child(2) {
     margin-right: 6px;
 }
}