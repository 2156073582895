.custom-dialog-content {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: 100%;
}
.custom-dialog-content input {
    width: 100%;
}
.custom-dialog-content button {
   margin-top: 5px;
}
.communication-dialog .p-dialog-content {
    overflow-y: hidden;
}