.p-fileupload-files {
    background: #ffffff;
    padding: 0.571rem 1rem;
    color: #333333;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}
.p-fileupload-files .p-fileupload-row>div {
    padding: 0.571rem 0.857rem;
    line-height: 1.5;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    flex: 1 1 auto;
    width: 25%;
}
.p-fileupload-files .p-fileupload-row > div .p-button.p-component.p-button-icon-only {
    padding: 0.08rem 0.3rem;
}
.p-fileupload .p-fileupload-row > div {
    padding: 0.571rem 0.857rem;
}
@media screen and (max-width: 767px) {
    .p-fileupload-row {
        flex-direction: column;
    }
    .p-fileupload-files .p-fileupload-row > div {
        width: auto;
    }
}