::ng-deep .invoicing-card p-card {
  padding: 15px;
}

.invoice-receipt ul {
  list-style-type: none;
  padding: 0px;
}

.invoice-receipt .p-md-6 {
  padding-bottom: 0px;
}

.invoice-textArea {
  min-height: 50px;
}

/* 
Media Queries 
*/
@media screen and (min-width: 768px) {
  ::ng-deep .invoicing-card p-card {
    width: 80% !important;
  }
}

@media screen and (min-width: 376px) and (max-width: 425px) {
  ::ng-deep .invoicing-card p-card {
    width: 420px;
  }
}

@media screen and (max-width: 375px) {
  ::ng-deep .invoicing-card p-card {
    width: 315px;
  }
}