.loginContainer {
  height: 100% !important;
}

.loginHeader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;

  height: 80px;
  display: flex;
  align-items: center;
}

.loginLogo {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  font-size: 30px;
  color: #fff;
  height: 80px;
  width: 120px;
  justify-content: center;
}
.loginLogo img {
  height: 60px;
}

.loginBox {
  width: 500px !important;
  max-width: 100% !important;
  margin: 20px;
}
.loginBox .p-card {
  border-radius: 10px;
  box-shadow: 0 1px 15px 0px rgb(0 0 0 / 4%);  
}
.loginBox .pi-envelope:before, .loginBox .pi-lock:before  {
position: relative;
opacity: 0.4;
// top: 2px;
}
.loginBox .pi-envelope:before {
  top: 0 !important;
}
.loginBox .pi-eye:before {
  position: relative;
  opacity: 0.4;
  top: 2px;
}

.loginHeaderInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.loginLogo div {
  width: 100px;
  height: 70px;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.loginTitle {
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
}

.loginTitle {
  color: var(--base-dark-blue);
  font-weight: 26px;
  font-family: "Montserrat-SemiBold";
}

.loginHeading h3 {
  margin: 0;
  color: var(--base-light-gray);
  font-family: "Montserrat-SemiBold";
  font-size: 30px;
}

.loginTitle h3,
.loginTitle h5 {
  text-align: center;
}

.loginTitle h3 {
  font-size: 24px;
  font-family: "Montserrat-SemiBold";
}

.loginTitle h5 {
  color: #979797;
  font-size: 15px;
  font-family: "Montserrat-Regular";
}

.passwrodCustomized .pi-eye,
.passwrodCustomized .pi-eye-slash {
  cursor: pointer;
}

.loginUsertypesTitle {
  padding-top: 70px;
  margin-bottom: -30px;
}

.loginUsertypesTitle h3 {
  font-weight: 500;
  font-family: "Montserrat-SemiBold";
  color: var(--base-dark-blue);
  font-size: 29px;
}

.loggedInTypeListbox {
  border: none;
}

.loggedInTypeListbox .p-listbox-item {
  justify-content: center !important;
  font-weight: 500;
  font-family: "Montserrat-SemiBold";
  color: var(--base-dark-blue) !important;
  padding: 25px 0px !important;
  font-size: 20px;
  // cursor: auto;
  cursor: pointer;
}

#loginchangepassword .p-button.p-button-link,
#login-footer .p-button.p-button-link {
  padding-left: 0px !important;
}

.p-card .p-card-content {
  padding: 1rem 1rem;
}

@media only screen and (max-width: 600px) {
  .loginUsertypesTitle h3 {
    font-size: 20px;
  }
  .loggedInTypeListbox .p-listbox-item {
    padding: 15px 0px !important;
  }
  .loginUsertypesTitle {
    padding-top: 30px;
  }
  .loginHeading h3 {
    text-align: center;
  }
}

@media only screen and (max-width: 520px) {
  .loginTitle {
    width: 100%;
  }
}

.dropdownSlideMenuPrimeng .p-slidemenu.p-slidemenu-overlay {
  height: 50px !important;
}

.p-slidemenu.p-slidemenu-overlay {
  height: 50px !important;
  width: 190px;
}

.p-datepicker table td {
  padding: 0px !important;
}

.p-datepicker table td > span {
  border-radius: 100% !important;
}

.p-datepicker {
  padding: 0.5rem !important;
}

// .p-calendar .p-datepicker {
//     min-width: 96% !important;
// }
.dashboardCalendar .p-datepicker {
  border: none !important;
}

.smallboxesContainer {
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.footerForgotPassword {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footerForgotPassword p {
  color: #979797;
}

@media only screen and (max-width: 480px) {
  .footerForgotPassword {
    flex-direction: column;
  }
}

@media only screen and (max-width: 374px) {
  #loginchangepassword-footer,
  #login-footer {
    flex-direction: column;
  }
  #loginchangepassword-btn,
  #login-btn {
    justify-content: center;
    margin-top: 30px;
  }
}
