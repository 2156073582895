.dashboardContent {
    box-shadow: none !important;
    background: transparent !important;
}
.adminCard{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 140px;
    border-radius: 3px;
    color:#FFFFFF;
    width: 200px;
}
.adminCard p{
    margin-top: 10px;
    margin-bottom: 0rem;
}
.adminCardBlue{
    background: transparent linear-gradient(0deg, #0263AC 0%, #388ECE 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 25px;
    cursor: pointer;
}  
.adminCardBlue .icon-class{
    font-size: 4rem;
}
.dashboard-card {
    // width: 280px;
    padding: 15px !important;
}
.noNumberCard {
    padding-top: 0px !important;
}
.admin-grid {
    margin-top: 1rem;
    padding: 0px 62px !important;
    display: flex;
    justify-content: flex-start;
}
.adminCard p {
    letter-spacing: 0px;
    color: #FFFFFF;
    text-shadow: 0px 2px 0px #00000017;
    text-transform: capitalize;
    opacity: 1;
    font-size: 14px;
    font-family: Montserrat-Regular;
}
.dashboard-card-content img {
    width: 35px;
    height: 35px;
}
.dashboard-card-content {
    padding-top: 0px;
}

@media screen and (max-width:399px){
    .admin-grid {
        padding: 0px 20px !important;
    }
}

@media screen and (max-width: 424px){
    .admin-grid {
        padding: 1rem;
    }
}

@media screen and (min-width: 400px) and (max-width:499px){
    .admin-grid {
        padding: 0px 50px !important;
    }
}
@media screen and (min-width: 550px) and (max-width:699px){
    .admin-grid {
        padding: 0px 150px !important;
    }
}
@media screen and (min-width: 700px) and (max-width:767px){
    .admin-grid {
        padding: 0px 200px !important;
    }
}
@media screen and (max-width: 991px){
    .admin-grid {
        justify-content: center;
    }
}
@media screen and (min-width: 992px) and (max-width:1200px){
    .admin-grid {
        padding: 0 0 0 50px !important;
    }
    // .adminCard {
    //     min-height: 250px;
    // }
    // .dashboard-card-content {
    //     padding-top: 0px;
    // }
}
// @media screen and (min-width: 1800px) and (max-width:2400px){

//     .adminCard {
//         min-height: 330px;
//     }
// }
// @media screen and (min-width: 2401px){

//     .adminCard {
//         min-height: 400px;
//     }
// }
@media screen and (min-width: 768px){

    .adminCard {
        width: 100%;
    }
}
@media screen and (min-width: 992px){

    .adminCard p {
        font-size: 12px;
    }
}