//contentWrapperClass
.ContentWrapperClass {
  padding: 0px 100px;
}
//date
 .topDate p{
  text-align: right;
  font-family: 'Montserrat-Regular';
  font-size: 16px;
  letter-spacing: 1.04px;
  color: #6E8DB9;
  opacity: 1;
}

 .clientDetailBox {
  background: #ffffff;
 // box-shadow: 0px 2px 26px #272D3B3D;
  border-radius: 8px;
   border-top:3px solid #359FF0;
  padding: 20px 10px 10px 20px;
   height:250px;
}
.clientDetailBox-patient {
  background: #ffffff;
  // box-shadow: 0px 2px 26px #272D3B3D;
  border-radius: 8px;
  border-top:3px solid #359FF0;
  padding: 0;
  margin-top:25px;
  height:250px;
}



.clientName {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
}

 .clientName p {
  font-family: 'Montserrat-SemiBold';
  color: #2880C2;
  opacity: 1;
  font-size: 30px;
}

 .statusBtnCommon .p-button {
  border: none;
  border-radius: 3px;
  opacity: 1;
  padding: 4px 31px;
  font-family: 'Montserrat-Regular';
  color: #FFFFFF;
  width: 100%;
}

 .p-tag-warning .p-button {
  background: #d9534f;
}

 .p-tag-warning .p-button:hover {
  background: #d9534f;
  color: #ffffff;
  border-color: transparent;
}

 .p-tag-warning .p-button:focus,
 .p-tag-success .p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0;
}

 .p-tag-success .p-button {
  background: #5cb85c;
}

 .p-tag-success .p-button:hover {
  background: #5cb85c;
}

 .clientCommomBtn .p-button {
  background: transparent linear-gradient(0deg, #0263AC 0%, #388ECE 100%) 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 3px;
  opacity: 1;
  padding: 4px 31px;
  font-family: 'Montserrat-Regular';
  color: #FFFFFF;
  width: 100%;
}

 .clientCommomBtn .p-button:enabled:hover {
  background: transparent linear-gradient(0deg, #0263AC 0%, #388ECE 100%) 0% 0% no-repeat padding-box;
  color: #ffffff;
  border-color: transparent;
}

 .clientCommomBtn .p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0;
}

//toolbar
 .p-details p {
  font-family: 'Montserrat-Regular';
  font-size: 14px;
  letter-spacing: 1.15px;
  opacity: 1;
  color: #55A0D8;
}

 .rightGroup {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
}

 .leftGroup {
  margin-top: 41px;
}

 .bottomBtnGroup {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

 .bottomBtn {
  padding-right: 8px;
}

 .bottomBtn:last-child {
  padding-right: 0px;
}

//accordion
 .cardAccordion .p-card {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 26px #272D3B1A;
  border-radius: 4px;
  opacity: 1;
  margin: 50px 0px 91px 0px;
}
 .cardAccordion .p-accordion-header-text {
  font-family: 'Montserrat-SemiBold';
}
 .cardHeader {
  padding: 8px 5px;
  font-family: 'Montserrat-SemiBold';
  color: #045490;
  font-size: 20px;
}

 .customAccordion .p-accordion .p-accordion-header .p-accordion-header-link {
  background: #D5E6FF;
  color: #045490;
  opacity: 1;
  margin: 14px 0px;
  border: transparent;
  font-family: 'Montserrat-SemiBold';
}

 .customAccordion .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:hover {
  background: #D5E6FF;
  color: #045490;
}

 .customAccordion .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0;
}

 .customAccordion .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: #D5E6FF;
  border-color: none;
  color: #045490;
  opacity: 1;
}

 .customAccordion .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  margin-right: -18px;
}

 .customAccordion .p-accordion-header-link {
  text-decoration: none;
}

 .customAccordion .p-accordion .p-accordion-content {
  border: none;
}

 .customAccordion .pi-chevron-right::before {
  content: "\e902";
  position: absolute;
  right: 15px;
  bottom: 16px;
  color:#426EAE;
}

 .customAccordion .pi-chevron-down:before {
  content: "\e902";
  position: absolute;
  right: 15px;
  bottom: 16px;
  transform: rotate(180deg);
  color:#426EAE;
}
.comm-table .pi-chevron-down:before {
  right: 11px;
  bottom: 10px;
}
//dropdown
.comm-dropdown .p-fluid .p-dropdown .p-dropdown-label {
  color: #000;
}
//
.comm-accordion-table-headings .cpaToolsTitle {
  font-size: 16px;
}
.comm-accordion-table-headings .p-input-icon-left>i, .comm-accordion-table-headings .p-input-icon-right>i {
  top: 38%;
}
.comm-title-btn {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (min-width: 320px) and (max-width:767px) {
  .ContentWrapperClass {
    padding: 0px 0px;
  }
  .topDate p{
    text-align:center;
  }

   .leftGroup {
    margin-top: 2px;
    text-align: center;
  }

   .rightGroup {
    align-items: center;
  }

  .clientName p {
    font-size: 25px;
    padding: 0px 12px;
    margin-bottom: 10px;
  }

  //bottombuttons
   .bottomBtn {
    padding-right: 0px;
    margin: 10px;
    width: 153px;
  }

   .bottomBtnGroup {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .customAccordion .p-accordion .p-accordion-content {
    padding: 0;
  }

}

@media screen and (min-width: 320px) and (max-width:430px) {
  .clientName {
    display: flex;
    justify-content: center;
  }
   .statusBtnCommon .p-button {
    margin-bottom: 9px;
}
.comm-title-btn {
  justify-content: center;
}
.comm-accordion-table-headings .cpaToolsTitle {
  text-align: center;
}
}
@media screen and (min-width: 768px) and (max-width:800px) {
   .clientCommomBtn .p-button {
    margin: 5px;
  }
 .bottomBtnGroup {
  justify-content: center;
 }
 .bottomBtn:last-child {
  padding-right: 12px;
}
}

