
.ContentWrapperClass {
    padding: 0px 100px;
}


 .paddcls {
    padding: 15px;
}


 .lpCalendar .p-calendar .p-datepicker {
    min-width: 100%;
    height: auto;
}

 .lpCalendar .p-datepicker table th {
    text-align: center;
    text-transform: uppercase;
}

 .lpCalendar .p-datepicker table th>span {
    font-family: 'Montserrat-Bold';
    font-size: 18px;
}

 .lpCalendar .p-datepicker table td {
    text-align: center;
    font-size: 20px;
}

 .lpCalendar .p-datepicker table td>span {
    margin-top: 4px;
    margin-bottom: 4px;
    font-family: 'Montserrat-SemiBold';
}

 .lpCalendar .p-datepicker {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 26px #272D3B1A;
    border-radius: 22px;
    opacity: 1;
    color: #9FAAC5;
    border: transparent;
}

 .lpCalendar .p-datepicker .p-datepicker-header {
    color: #9FAAC5;
    border-bottom: transparent;
    margin-top: 6px;
}

 .lpCalendar .p-datepicker .p-datepicker-header .p-datepicker-title {
    display: flex;
}

 .lpCalendar .pi-chevron-left:before,
 .lpCalendar .pi-chevron-right:before {
    color: #9FAAC5;
}

 .lpCalendar .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month,
 .lpCalendar .p-datepicker .p-datepicker-header .p-datepicker-title select {
    color: #9FAAC5;
    border: 1px solid #9FAAC5;
    font-family: 'Montserrat-Medium';
    background: #FFFFFF;
}

 .lpCalendar .p-datepicker table td.p-datepicker-today>span {
    background: #0293FF 0% 0% no-repeat padding-box;
    opacity: 1;
    color: #FFFFFF;
    font-family: 'Montserrat-Bold';
}

 .lpCalendar .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0;
}

 .lpCalendar .p-datepicker table td>span.p-highlight {
    background: transparent;
    color: #9FAAC5;
    font-family: 'Montserrat-Black';
    font-size: 19px;
}

 .lpCalendar .p-datepicker table td>span.p-highlight::after {
    color: #00CCF2;
    background: transparent;
    content: '.';
    position: absolute;
    font-weight: bold;
    bottom: -9px;
    font-size: 25px;
}


 .confirmDialogFooterBtn .p-dialog {
    max-height: 42%;
    width: 464px !important;
}

 .confirmDialogFooterBtn .p-dialog .p-dialog-header {
    padding: 10px 10px;
}

 .confirmDialogFooterBtn .p-dialog .p-dialog-header .p-dialog-title {
    font-weight: 600;
    font-size: 19px;
    color: #0263AC;
    font-family: 'Montserrat-SemiBold';
}

 .confirmDialogFooterBtn .p-dialog .p-dialog-content {
    background: #ffffff;
    color: #0263AC;
    padding: 25px 10px 25px 10px;
    font-family: 'Montserrat-Regular';
}

 .confirmDialogFooterBtn .p-button {
    color: #ffffff;
    background: #0263AC;
    border: 1px solid #0263AC;
    padding: 2px 18px;
}

 .confirmDialogFooterBtn .p-button:enabled:hover {
    background: #0263AC;
    color: #ffffff;
    border-color: #0263AC;
}


 .pngTableCardLp .p-card {
    color: #495057;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 26px #272D3B12;
    border-radius: 22px;
}

 .pngTableCardLp .p-card .p-card-content {
    padding: 1rem 0rem;
    margin-left: -10px;
}
/*  .pngTableCardLp .p-card .p-card-body {
    height: 424px;
} */

 .pngTableLp .p-datatable .p-datatable-header {
    background: #FFFFFF;
    border: transparent;
    padding: 0px 1rem;
    margin-top: -15px;
    font-weight: normal;
}

 .pngTableLp .p-datatable .p-datatable-header p {
    color: #9FAAC5;
    font-family: 'Montserrat-Medium';
    font-size: 20px;
    margin-top: 0.5rem;
}

 .pngTableLp .lpTableSearch {
    border-radius: 23px;
    border: 1px solid #0293FF63;
}

.pi-search:before {
    color: #C1C9D5;
    cursor: pointer;
    background: 0% 0% no-repeat padding-box;
    opacity: 1;
}

.lpTableSearch::placeholder {
    color: #C1C9D5;
    font-size: 18px;
    font-family: 'Montserrat-Regular';
    letter-spacing: 1px;
    opacity: 1;
}

 .lpTableSearch .p-inputtext {
    border-radius: 20px;
    border: 1px solid #0293FF63;
    width: 280px;
}

 .p-inputtext:enabled:hover {
    border-color: #0293FF63;
}

 .p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0;
    border: 1px solid #0293FF63;
}

 .pngTableLp .p-datatable .p-datatable-thead>tr>th {
    font-size: 16px;
    padding: 0.3rem 1rem;
    border-bottom: 1px solid #B7C0D4;
    color: #9FAAC5;
    opacity: 1;
    font-family: "Montserrat-SemiBold";
    word-spacing: 3px;
    text-transform: uppercase;
}

 .pngTableLp .p-datatable .p-datatable-tbody>tr>td a {
    color: #9FAAC5;
    font-family: 'Montserrat-Regular';
}

 .pngTableLp .p-datatable .p-datatable-tbody>tr>td a:hover {
    text-decoration: none;
}

 .pngTableLp .p-datatable .p-datatable-tbody>tr>td {
    padding: 0.3rem 1rem;
    font-size: 16px;
    color: #9FAAC5AD;
    font-family: "Montserrat-Regular";
    opacity: 1;
    word-spacing: 0px;
}


/*png action buttons*/

.pngActionBtn {
    background: none !important;
    color: #9FAAC5AD;
    border: none;
    padding-left: 0px;
}

.pngActionBtn:hover {
    background: none !important;
    color: #9FAAC5;
    border: none;
}

.pngActionBtn:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0;
}

.viewAllAppointments {
    display: flex;
    justify-content: flex-end;
}

.viewAllAppointments a {
    font-size: 20px;
    font-family: "Montserrat-Medium";
    padding: 0px 30px 0px 0px;
    color: #9FAAC5;
    text-decoration: none;
}

 .lpSummaryCardBlue {
    background: transparent linear-gradient(0deg, #0D4695 0%, #1E60C0 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 22px;
    opacity: 1;
}

 .lpSummaryCards {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

 .lPsummaryCardContent {
    padding: 10px;
}

 .lpSummaryCards p {
    color: #FFFFFF;
    text-shadow: 0px 2px 0px #00000017;
    text-transform: capitalize;
    opacity: 1;
    padding: 10px 10px;
    margin-top: 0;
    margin-bottom: 1px;
    font-family: 'Montserrat-Regular';
    font-size: 14px;
font-weight: 600;
letter-spacing: 1px;
}

.lpSummarySvg svg {
    color: #FFFFFF;
    opacity: 1;
    padding-top: 15px;
    padding-right: 9px;
    width: 50px;
    height: 50px;
}


 .pngCardNewsFeed .p-card {
    color: #495057;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 26px #272D3B12;
    border-radius: 22px;
    border-bottom-right-radius: 0;
    opacity: 1;
}

 .pngCardNewsFeed .p-card-content {
    padding: 0px 1rem;
    height: 255px;
    overflow-y: scroll;
    overflow-x: hidden;
    color: #66718E;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 22px;
}

 .pngCardNewsFeed .p-card .p-card-body {
    padding: 0px;
}

 .newsFeedCardHeader .p-card-header {
    font-size: 14px;
    font-weight: 600;
    color: #244996;
    padding: 10px 20px;
    font-family: 'Montserrat-Medium';
}

 .pngCardNewsFeed .newsFeedDate {
    font-size: 13px;
    margin: 1px;
    color: #C6D2EE;
    font-family: 'Montserrat-Light';
}

 .pngCardNewsFeed .newsDetail {
    font-size: 12px;
    margin: 1px;
    color: #9FAAC5;
    font-family: 'Montserrat-Light';
}

.newsFeedHighlight {
    font-size: 12px;
    color: #66718E;
    margin: 1px;
    font-family: "Montserrat-Medium";
}
.newsfeed-img {
    width: 200px;
}

 .newsFeedImg img {
    height: 100%;
    width: 100%;
}

 .pngTableCardLp .p-card .p-card-body {
    height: 423px;
}


.appCardPadd {
    padding-left: 15px !important;
}

.marginBottom {
    margin-bottom: 100px;
}

.lpAppointmentCard {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #000000;
}

.lpAppointmentCardContent {
    padding: 12px 43px;
}

.lpAppointmentCard h3 {
    font-size: 18px;
    font-family: 'Montserrat-Bold';
    padding: 7px 0px;
    opacity: 1;
    color: #FFFFFF;
    letter-spacing: 1px;
}

.lpAppointmentCard p {
    text-shadow: 0px 1px 0px #FFFFFF8C;
    opacity: 0.46;
    font-family: 'Montserrat-Medium';
    font-size: 14px;
    margin-top: -10px;
    margin-bottom: 1px;
    opacity: 1;
    color: #FFFFFF;
}

.lpAppBlueCard {
    background: transparent linear-gradient(180deg, #0194FC 0%, #059AEA 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 10px #272D3B0F;
    border-radius: 22px;
    opacity: 1;
}

.lpAppLightBlueCard {
    background: transparent linear-gradient(124deg, #0194FC 0%, #05CBEA 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 10px #272D3B0F;
    opacity: 1;
    border-radius: 22px;
}

.lpAppGreenCard {
    background: transparent linear-gradient(124deg, #0194FC 0%, #05CBEA 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 10px #272D3B0F;
    border-radius: 22px;
    opacity: 1;
}


.lpAppSvgIcons{
    width: 30px;
    height: 30px;
}

.lpAppSvgColor Svg .a {
    fill: #FFFFFF;
    opacity: 1;
}

 .copyrightMsg {
    color: #244996;
    opacity: 0.83;
    text-align: center;
    letter-spacing: 0px;
    font-family: 'Montserrat-Regular';
    font-size: 16px;
    position: absolute;
    bottom: 5px;
    margin: 0px;
    width: 100%;
}

/*lp charts*/
   .lpChartHeading {
      font-size: 15px;
      color: #78849E;
      font-family: Montserrat-Medium;
      margin: 0px 0px 10px 0px;
  }

 .lpGraphs .chartjs-render-monitor {
    background: #FFFFFF;
    box-shadow: 0px 2px 26px #272D3B1A;
    border-radius: 4px;
    opacity: 1;
    padding: 10px;
}

 .lpGraphPadding {
    padding: 15px;
}

.marginBottom {
    margin-bottom: 32px;
}

@media screen and (min-width: 320px) and (max-width:374px) {
      .p-toast .p-toast-message {
        width: 80%;
        margin-left: 100px;
    }
}

@media screen and (min-width: 320px) and (max-width:424px) {
      .confirmDialogFooterBtn .p-dialog .p-dialog-footer {
        display: flex;
        justify-content: flex-end;
        padding: 10px 4px;
    }
}

@media screen and (min-width: 375px) and (max-width:767px) {
      .p-toast .p-toast-message {
        width: 80%;
        margin-left: 90px;
    }
}

@media screen and (min-width: 320px) and (max-width:767px) {
    .ContentWrapperClass {
        padding: 0px 0px;
    }
      .paddcls {
        padding: 7px;
    }
    .appCardPadd {
        padding-left: 7px !important;
    }
    .lpTableHeader p {
        text-align: center;
        margin-bottom: 0rem;
    }
      .lpCalendar .p-calendar .p-datepicker {
        min-height: auto;
    }
      .confirmDialogFooterBtn .p-dialog {
        max-height: 42%;
        width: 300px !important;
    }

     .pngTableCardLp .p-card .p-card-body {
        height: 465px;
        }
}

@media screen and (min-width: 426px) and (max-width:991px) {
    .lPsummaryCardContent {
        padding: 56px 93px;
    }
    .lpAppointmentCardContent {
        padding: 27px 117px;
    }
}

@media screen and (min-width: 992px) and (max-width:1230px) {
    .ContentWrapperClass {
        padding: 0px 0px !important;
    }
      .lpCalendar .p-datepicker table th {
        padding: 7px;
    }
}

@media screen and (min-width: 1620px) and (max-width:2500px) {
      .pngCardNewsFeed .p-card-content {
        height: 357px;
    }
    .lPsummaryCardContent {
        padding: 33px 45px;
    }
    .lpAppointmentCardContent {
        padding: 32px 60px;
    }
    .lpAppSvgIcons{
        width: 35px;
        height: 35px;
        opacity: 1;
    }
    .newsFeedHighlight {
        font-size: 23px;
    }
    .pngCardNewsFeed .newsFeedDate {
        font-size: 15px;
    }
    .pngCardNewsFeed .newsDetail {
        font-size: 21px;
    }
}

@media (min-width: 992px) {
    .marginBottom .p-lg-2 {
        padding: 1rem !important;
    }
}
