// var(--base-light-blue);: #359ff0;
// $base-dark-blue: #053d67;

:root {
  --base-light-blue: #359ff0;
  --base-dark-blue: #045490;
  --base-light-gray: #EEEEEE;
  --base-hover-blue: #00A1F0;


  //Checklist Subs - Super Admin
  --green-card: #43D5CB;
  --green-card-content: #0C8179;
  --blue-card: #3C79B8;
  --blue-card-content: #ffffff;
  --grey-card: #D8DBE2;
  --grey-card-content: #808080;
}

 

