.manageSubsContent .p-card .p-card-body {
  padding-bottom: 45px;
}

.manageSubsTable table tr td {
  font-size: 18px;
  color: #495057DE;
  font-family: Montserrat-SemiBold;
}

.manageSubsTable .p-selectbutton .p-button.p-highlight {
  background: #0263AC;
  color: #fff !important;
}

.manageSubsTable .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background: #F4F7FE;
}

.mSubs-search-field label {
  font-size: 16px;
  color: #495057DE;
}

.mSubs-search-field .p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none;
}

.mSubs-search-field .p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  text-transform: capitalize;
}

.selectBtn .p-buttonset .p-button:first-of-type, .selectBtn .p-buttonset .p-button:last-of-type {
  width: 150px;
  color: #1B7BBD
}

.mSubs-search-field .p-field {
  margin-bottom: 2rem;
}

.companiesTitle {
  padding: 25px 10px 0px 32px;
  margin: 0px;
}

// Media Queries
@media screen and (max-width: 767px) {
  .manageSubsContent .p-card .p-card-body {
    padding: 0rem;
    padding-bottom: 45px;
  }
  .companiesTitle {
    padding: 10px 0px 0px 15px;
  }
  .manageSubsTable table tr td {
    font-size: 15px;
  }
}
