//contentWrapperClass
.ContentWrapperClass {
  padding: 0px 100px;
}

//dialog

.reportDialog .p-dialog .p-dialog-header-icons {
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.reportDialog .p-dialog .p-dialog-header {
  border-bottom: none;
  padding: 1rem 0.5rem;
}

.reportDialog .pi-times:before {
  content: "\e90b";
  font-size: 30px;
  opacity: 1;
  color: #AFAFAF;
}
.comm-type-dropdown .pi-times:before{
  color: #6c757d;
  font-size: 15px;
  position: relative;
  top: 1px;

}

 .tapLogo img{
  width: 60px;
  height: 60px;
  background: transparent;
  margin-top: -24px;
}
.reportLogo img{
  width: 60px;
  height: 60px;
  background: transparent;
  margin-top: -24px;
}
 .reportLogo {
  width: 100%;
  text-align: end;
}
 .reportDialogHeader {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #E8E8E8;
  flex-wrap: wrap;
  align-content: baseline;
}

.reportDialogHeader p {
  font-family: "Montserrat-SemiBold";
  font-size: 29px;
  color: #2880C2;
  opacity: 1;
  margin-bottom: 2px;
  font-weight: 600;
}

 .clientName p {
  font-family: 'Montserrat-SemiBold';
  color: #2880C2;
  opacity: 1;
  font-size: 29px;
  // margin-left: -10px;
  margin-bottom: 1px;
  margin-top: 25px;
  font-weight: 600;
}

 .p-details p {
  font-family: 'Montserrat-Regular';
  font-size: 14px;
  letter-spacing: 1.15px;
  color: #55A0D8;
  opacity: 1;
  margin-bottom: 7px;
}

 .rightGroup {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
}

//report sumary table
 .reportTable .p-datatable-resizable .p-datatable-tbody>tr>td,
.p-datatable-resizable .p-datatable-tfoot>tr>td,
.p-datatable-resizable .p-datatable-thead>tr>th {
  overflow: hidden;
  white-space: pre-wrap;
}

 .reportTable {
  margin-top: 20px;
}

 .tablereportHeading {
  border-bottom: 2px solid #E8E8E8;
  margin-top: 58px;
}

 .tablereportHeading p {
  font-family: 'Montserrat-SemiBold';
  font-size: 29px;
  color: #2880C2;
  opacity: 1;
  margin-bottom: 2px;
  font-weight: 600;
}

 .reportTable .p-datatable .p-datatable-tbody>tr>td {
  border: 1px solid #258AC7;
}

 .reportTable .p-datatable .p-datatable-tbody>tr>td:first-child {
  color: #045490;
  font-family: "Montserrat-Bold";
  font-size: 15px;
  vertical-align: top;
}

 .reportTable .p-datatable .p-datatable-tbody>tr>td:last-child {
  color: #045490;
  font-family: "Montserrat-Medium";
  font-size: 15px;
  width: 50%;
}

//classififcation table
 .classificationTable {
  margin-top: 20px;
}

 .classificationTable .p-datatable .p-datatable-tbody>tr>td {
  border: 1px solid #258AC7;
}

 .classificationTable .p-datatable .p-datatable-tbody>tr>td {
  font-family: "Montserrat-Bold";
  font-size: 15px;
  color: #045490;
  opacity: 1;
  width: 50%;
}

//all table td paddings
 .therapyListTable .p-datatable .p-datatable-tbody>tr>td,
 .classificationTable .p-datatable .p-datatable-tbody>tr>td,
 .reportTable .p-datatable .p-datatable-tbody>tr>td {
  padding: 4px 10px 15px 10px;
}

//therapy detail table
 .therapyListTable {
  margin-top: 20px;
}

 .therapyListTable .p-datatable .p-datatable-tbody>tr>td:first-child {
  color: #045490;
  font-family: "Montserrat-Bold";
  font-size: 15px;
  width: 50%;
}

 .therapyListTable .p-datatable .p-datatable-tbody>tr>td:last-child {
  color: #045490;
  font-family: "Montserrat-Medium";
  font-size: 15px;
}
.report-wrapper {
  padding-top: 0.5rem;
}
.report-title {
  color: #045490;
  font-family: "Montserrat-Bold";
  font-size: 15px;
}
.report-title-value {
  color: #045490;
  font-family: "Montserrat-Medium";
  font-size: 15px;
}

//media queries
@media screen and (min-width: 320px) and (max-width: 424px){
.reportDialogHeader {
    text-align: center;
    justify-content: center;
   }
   .reportDialogHeader p {
    font-size: 30px;
   }
   .tapLogo img {
    margin-top: 0px;
   }
   .reportLogo img {
    margin-top: 0px;
   }
   .reportLogo {
   text-align: center;
   }
   .reportDialogHeader{
     flex-wrap: wrap-reverse;
   }
    .reportDialog .p-dialog .p-dialog-content {
     padding-top: 5px; 
   }
}
@media screen and (min-width: 320px) and (max-width:767px) {
  .ContentWrapperClass {
    padding: 0px 0px;
  }

  .reportDialogHeader {
    text-align: center;
  }

  .clientName p {
    text-align: center;
  }

  .tablereportHeading p {
    text-align: center;
  }

   .leftGroup {
    text-align: center;
  }

   .rightGroup {
    text-align: center;
    align-items: center;
  }

  .clientName p {
    font-size: 25px;
  }

  .tablereportHeading {
    margin-top: 26px;
  }
  .clientName p {
    margin-top: 12px;
  }
}

@media screen and (max-width:991px) {
   .reportDialog .p-dialog {
    width: 90vw !important;
    max-width: 100%;
  }
}
