.chartsHeading {
  color: #78849E;
  font-size: 20px;
  padding-top: 60px;
  padding-bottom: 33px;
}

.barDataGraph .chartjs-render-monitor {
  background: #FFFFFF;
  height: 400px !important;
  box-shadow: 0px 2px 26px #272d3b1a;
  border-radius: 3px;
  padding: 15px;
}