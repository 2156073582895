.checklist-card p-card {
  padding: 15px;
}

.helping-and-chart-wrap .bgColor {
  flex: 1;
}

.checklist-headerGrid {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.checklist-search-wrap {
  display: inline-flex;
  justify-content: flex-end;
}

.checklist-search-wrap .search-and-icon, .checklist-search {
  width: 100%;
}

.toolbarContent {
  display: flex;
  /* establish flex container */
  flex-direction: column;
  /* make main axis vertical */
  justify-content: flex-end;
  /* align-items: center;  */
  margin-bottom: 10px;
}

.knobContent {
  display: flex;
  justify-content: flex-end;
}

.toolbarwrapL {
  justify-content: flex-end;
  padding: 0;
  margin-bottom: 10px;
}

.toolbarwrapR {
  align-self: flex-end;
  vertical-align: top;
  text-align: justify;
  margin-top: 20px;
}

.p-toolbar.toolbars {
  background-color: #F4F7FE;
  display: flex;
  padding: 10px;
  vertical-align: baseline;
  align-self: flex-end;
  box-shadow: none !important;
  border: 0 !important;
  margin-bottom: 15px;

}

.p-toolbar-group-left {
  padding-left: 7px;
  color: #3C4A57;
}

.checkListTabs {
  flex-wrap: nowrap;
  overflow-x: auto;
  white-space: nowrap;
  cursor: pointer;

}

// .checkListTabsCard:first-child {
//   padding-left: 0px;
// }

.checkListTabsCard .p-card-body {
  padding: 10px !important;
}

.checkListTabsCard > .p-card {
  box-shadow: none !important;
  padding-top: 30px;
}

.checkListTabsCard {
  flex-wrap: nowrap;
  overflow: hidden;
  padding: 10px;
  width: 200px;
  padding: 0px 64px 0px 0px;
}

.checkListTabsIcon {
  position: relative;
  top: calc(50% - 25px);
  font-size: 2em;
  // filter: invert(28%) sepia(98%) saturate(376%) hue-rotate(126deg) brightness(96%) contrast(99%);
}

.checkListTabsIcon svg defs style {
  stroke: #076760;
}

.icon-center {
  margin-left: 5px;
  margin-right: 5px;
  width: 100%;
  height: 100px;
  text-align: center;
}

.p-tag {
  padding-left: 15px;
  padding-right: 15px;
  font-weight: bold;
  min-width: 100%;
}

.p-tag.p-tag-green {
  background-color: var(--green-card) !important;
  color: var(--green-card-content) !important;
  height: 150px;
}

.p-tag.p-tag-blue {
  background-color: var(--blue-card) !important;
  color: var(--blue-card-content) !important;
  height: 150px;
}

.p-tag.p-tag-grey {
  background-color: var(--grey-card) !important;
  color: var(--grey-card-content) !important;
  height: 150px;
}

.icon-color-success {
  color: var(--green-card-content) !important;
}

.icon-color-danger {
  color: var(--blue-card-content) !important;
}

.icon-color-primary {
  color: var(--grey-card-content) !important;
}

.tab-desc {
  font-size: 16px;
  text-align: center;
  white-space: normal;
  padding-top: 15px;
  font-weight: bold;
  color: #364451;
  font-family: Montserrat-SemiBold;
}

// .checkListTabsIcon.pi {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
.topActiveIcon {
  display: flex;
  padding-bottom: 32px;
  padding-top: 13px;
  justify-content: center;
}

.hidden {
  visibility: hidden;
}

.checkListTabs::-webkit-scrollbar {
  width: 1rem;
  height: 5px;
}

.checkListTabs::-webkit-scrollbar-track {
  border-radius: 10px;
}

.checkListTabs::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #d8dbe2;
}

d.checkListTabs::-webkit-scrollbar:vertical {
  display: none;
}

.tableStatusTag {
  text-align: center;
  // font-weight: bold !important;
  font-family: 'Montserrat-SemiBold';
}

.textBold {
  font-weight: bold;
}

.checkListTable .p-progressbar {
  height: 6px;
  background-color: #b2d2e8;
}

.checkListTable .p-progressbar .p-progressbar-value {
  border-radius: 5px;
}

.checkListTable .p-button .p-button-icon-left {
  font-weight: lighter;
}

.checkListTabsCard .icon-center {
  margin: 0px;
}

.pToolBarText {
  color: var(--toolbar-color);
  font-size: 26px;
  font-family: Montserrat-SemiBold;
  padding: 10px;
  margin: 0px;
}

.checklistViewBtn {
  padding: 0px !important;
}

.progressValueText {
  color: #495057DE;
}

.checkListTable table tr th {
  font-size: 16px !important;
  font-family: Montserrat-SemiBold;
  // padding-left: 0px !important;
}

.checkListTable table button .p-link:focus {
  box-shadow: none !important;
}

.checkListTable table tr td {
  font-size: 16px !important;
  font-family: Montserrat-Regular;
}

// Paginator
.checkListTable .p-paginator .p-paginator-current {
  color: #9FAAC5;
}

.checkListTable .p-paginator .p-paginator-first,
.checkListTable .p-paginator .p-paginator-prev,
.checkListTable .p-paginator .p-paginator-next,
.checkListTable .p-paginator .p-paginator-last {
  color: #9FAAC5;
}

.checkListTable .p-paginator .p-paginator-pages .p-paginator-page,
.checkListTable .p-dropdown .p-dropdown-trigger {
  color: #9FAAC5;
}

.p-fluid .p-dropdown .p-dropdown-label {
  color: #9FAAC5;
}

.checkListTable .p-dropdown {
  background: #ffffff;
  border: 1px solid #9FAAC5;
}

.checkListTable .p-dropdown:hover {
  border: 1px solid #9FAAC5;
}

.checkListTable .p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: #9FAAC5;
}

.checkListTable .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #9FAAC5;
}

.checkListTable .p-progressbar .p-progressbar-value {
  background: #1978BB !important;
}

.toolbarwrapR .p-knob.p-component {
  cursor: no-drop;
}

.toolbarwrapR p-knob .p-knob-text {
  fill: #1978BB;
  font-family: Montserrat-SemiBold;
}

.toolbarwrapR p-knob .p-knob-value {
  stroke: #1978BB;
}

.toolbarwrapR p-knob .p-knob-range {
  stroke: #B2D2E8;
}

.toolbarContent {
  padding: 10px 0px 0px;
}

::-webkit-input-placeholder { /* Edge */
  font-size: 16px !important;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  font-size: 16px !important;
}

::placeholder {
  font-size: 16px !important;
}

/* 
Media Queries 
*/

@media screen and (max-width: 991px) {
  .toolbarwrapR svg {
    width: 150px !important;
    height: 150px !important;
  }
  .checklist-top-title, .checklist-search-wrap {
    width: 100%;
  }
  .checklist-top-title {
    text-align: center;
  }

}

@media screen and (min-width: 768px) {
  .checkListTable .p-paginator .p-paginator-current {
    position: inherit;
    flex-basis: 0%;
    position: absolute;
  }
  .checklist-card p-card {
    width: 80% !important;
  }
  .checklist-headerGrid .p-input-icon-right > .p-inputtext {
    padding-right: 15rem !important;
  }
}

@media screen and (max-width: 767px) {
  .superAdminTitles {
    font-size: 16px !important;
    font-family: Montserrat-SemiBold;
  }
  .pToolBarText {
    font-size: 12px !important;
  }
  .p-toolbar, .p-toolbar-group-left, .p-toolbar-group-right {
    justify-content: center !important;
  }
  ::-webkit-input-placeholder { /* Edge */
    font-size: 12px !important;
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-size: 12px !important;
  }

  ::placeholder {
    font-size: 12px !important;
  }
  .helping-and-chart-wrap {
    flex-direction: column;
  }

  .checklist-desc {
    align-self: stretch;
    margin-bottom: 10px !important;
  }

  .knobContent {
    justify-content: center;
  }

  .checklist-top-title {
    width: 100%;
    text-align: center;
  }

  .checklist-search-wrap {
    width: 100%;
    margin-bottom: 10px;
  }

  .checklist-search-wrap .search-and-icon {
    width: 100%;
  }

  .headertext {
    text-align: center;
  }

  .p-toolbar-group-right {
    width: 100%;
  }

  .p-toolbar-group-right .headertext {
    width: 100%;
    text-align: center;
  }

}

@media screen and (min-width: 425px) and (max-width: 767px) {
  .checkListTabsCard {
    width: 385px;
  }
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .checkListTabsCard {
    width: 335px;
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .checkListTabsCard {
    width: 275px;
  }
}

@media screen and (min-width: 320px) and (max-width: 375px) {
  // paginator
  .checkListTable .p-paginator .p-paginator-current {
    font-size: 15px;
    text-align: center;
  }
  .checkListTable .p-paginator .p-paginator-first,
  .p-paginator .p-paginator-prev,
  .p-paginator .p-paginator-next,
  .p-paginator .p-paginator-last {
    margin: -12px;
  }
  .checkListTable .p-paginator {
    justify-content: center !important;
  }
}