/* You can add global styles to this file, and also import other style files */

@import '../node_modules/primeicons/primeicons.css';
@import '../node_modules/primeng/resources/primeng.min.css';
@import '../node_modules/primeflex/primeflex.css';
@import '../node_modules/font-awesome/css/font-awesome.min.css';
/* @import '../node_modules/primeng/resources/themes/nova-accent/theme.css'; */
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

@import '../src/assets/styles/fonts';
@import '../src/assets/styles/icons';
@import '../src/assets/styles/app-utilities';

// png components
@import '../src/assets/styles/pngComponentsfiles/badges.scss';
@import '../src/assets/styles/pngComponentsfiles/buttons.scss';
@import '../src/assets/styles/pngComponentsfiles/cards.scss';
@import '../src/assets/styles/pngComponentsfiles/checkboxradiobtns.scss';
@import '../src/assets/styles/pngComponentsfiles/dialogs.scss';
@import '../src/assets/styles/pngComponentsfiles/dropdownmenu.scss';
@import '../src/assets/styles/pngComponentsfiles/fileupload.scss';
@import '../src/assets/styles/pngComponentsfiles/inputfields.scss';
@import '../src/assets/styles/pngComponentsfiles/multiselect.scss';
@import '../src/assets/styles/pngComponentsfiles/panelmenu.scss';
@import '../src/assets/styles/pngComponentsfiles/panels.scss';
@import '../src/assets/styles/pngComponentsfiles/table.scss';
@import '../src/assets/styles/pngComponentsfiles/tabs.scss';
@import '../src/assets/styles/pngComponentsfiles/togglebtns.scss';

@import '../src/assets/styles/primeNg.Override';
@import '../src/assets/styles/global';

//themefiles
@import '../src/assets/styles/themeFiles/themeBlue.scss';
