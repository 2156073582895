.pngTableEmptyMessage .p-datatable-tbody {
    position: absolute;
    width: 100%;
    text-align: center;
}
.p-paginator .p-paginator-current {
    float: left;
    position: absolute;
    left: 0;
  }
  .p-datatable-resizable>.p-datatable-wrapper {
    cursor: pointer;
  }
  
  .p-fluid .p-dropdown .p-dropdown-label {
    color: #9FAAC5;
  }
  

  .p-datatable .p-datatable-tbody > tr > td:last-child .p-button{
    padding-left: 0px;
  }
  .payments-detail-table .p-datatable-resizable .p-datatable-tbody>tr>td:nth-child(7), .body-chart-table .p-datatable-resizable .p-datatable-tbody>tr>td:nth-child(7) {
    width: 150px;
    display: inline-block;
    text-overflow: ellipsis;
  }
  //.paymentsDetailTable .p-datatable-resizable .p-datatable-tbody>tr>td:nth-child(2), 
  /* 
  Media Queries 
  */
  
@media screen and (max-width: 991px) {
    .p-paginator .p-paginator-current {
      position: inherit;
      flex-basis: 100%;
    }
  }
  
@media screen and (max-width: 767px){
    .pngTableEmptyMessage .p-datatable-tbody {
        position: relative;
        text-align: center;
        white-space: nowrap;
        // bottom: 5px;
    }
}
//Font Change of Table Headings and Table Data (25 Nov, 2021)
.p-datatable .p-datatable-thead > tr > th {
  font-size: 16px !important;
}
.p-datatable .p-datatable-tbody > tr > td {
  font-size: 14px !important;
}