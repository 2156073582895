
#overlaypaneldiv-search,
#overlaypaneldiv-add {
  display: none;
}

.pngPanelMain p-header,
.pngPanel p-header {
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
}

.p-panel .p-panel-header,
.p-panel .p-panel-content,
.p-panel .p-panel-footer {
  border: none !important;
}

.p-panel .p-panel-header {
  background-color: transparent !important;
}

.p-panel .p-panel-header .globalSearch {
  margin-right: 15px;
}

.pngPanel-footer {
  margin: 10px 0px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.pngPanel-footer .p-button {
  margin-left: 5px;
}


.p-panel-header {
  font-family: "Montserrat-SemiBold";
  font-size: 18px;
}

.pngPanelMain > div > .p-panel-header {
  font-size: 24px !important;
}

.pngPanelMain .p-panel-header {
  padding-bottom: 0px !important;
}

.pngPanelMain .p-panel .p-panel-content {
  padding-top: 6px !important;
}

.pngPanelList .p-panel-header {
  padding-top: 0px !important;
  padding-right: 0px;
}

.p-panel .p-panel-content .p-panel .p-panel-content {
  padding-left: 0px !important;
}

.pngPanel .p-panel-header {
  padding-left: 0px !important;
}

@media screen and (max-width:767px){
  .p-panel .p-panel-header .globalSearch {
    margin-right: 0px;
  }
}
@media screen and (max-width:425px){
  .pngPanel-footer {
    justify-content: center;
  }
}
@media screen and (max-width:400px){
   .p-panel .p-panel-header {
    width: 100%;
  }
}
@media screen and (max-width:380px){
   .p-input-icon-right > .p-inputtext {
    // padding-right: 1rem;
    width: 100%;
    //  text-overflow: ellipsis;
  }
}