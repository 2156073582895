// @import '../../../assets/styles/variables';
@import "../styles/variables";
@import "../styles/appComponentsfiles/login.scss";
@import "../styles/appComponentsfiles/saLandingPage.scss";
@import "../styles/appComponentsfiles/saChecklist.scss";
@import "../styles/appComponentsfiles/saInvoicing.scss";
@import "../styles/appComponentsfiles/saDashboard.scss";
@import "../styles/appComponentsfiles/saAnalytics.scss";
@import "../styles/appComponentsfiles/saManageSubs.scss";
@import "../styles/appComponentsfiles/landingPageNew.scss";
@import "../styles/appComponentsfiles/communications.scss";
@import "../styles/appComponentsfiles/bodyChart.scss";
@import "../styles/appComponentsfiles/cpaTools.scss";
@import "../styles/appComponentsfiles/reports.scss";

html,
body {
  height: 100%;
  // overflow: hidden;
}

body {
  font-family: "Montserrat-Regular";
  margin: 0;
  padding: 0;
  background-color: #fafbff;
}
p-button{
  -webkit-appearance: none !important;
}
div:focus {
  outline: none !important;
}
.displayNone{
  display: none !important;
}
.p-datepicker .p-datepicker-buttonbar {
  display: none;
}
.displayBlock{
  display: block !important;
}
.p-toast-top-right {
  top: 85px !important;
}
.manageSubsClass{
  display: flex;
  flex-direction: row;
  justify-content: space-between
}
.print-preview {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.noPaddingClass {
  padding: 0px;
}
.rightallign {
  right: 30px !important;
}

.p-component {
  font-family: "Montserrat-Regular";
}

input:-webkit-autofill {
  -webkit-box-shadow: none !important;
  background: transparent !important;
}

.p-inputtext {
  color: #000;
}

.p-button-label {
  word-break: break-word;
  cursor: pointer;
}

.customError {
  padding-top: 3px;
  padding-left: 3px;
}

.subsChecklistdiv {
  width: 100%;
  padding-left: 11px;
  padding-right: 23px;
  margin-bottom: 10px;
}

.subsCheckboxView label {
  margin-bottom: 0.1rem;
}

/*Card*/
.p-card {
  box-shadow: none;
}

.tap-card {
  border: none;
  padding: 10px;
}

.checklistMainSection .p-card .p-card-body {
  padding: 2rem;
}

.dashboardTable .p-datatable .p-datatable-thead > tr > th {
  background-color: transparent !important;
}

.dashboardTable .p-datatable .p-datatable-thead > tr > th,
.dashboardTable .p-datatable .p-datatable-tbody > tr > td {
  padding: 7px 5px !important;
  border: none;
}

.dashboardCalendar .p-calendar {
  display: block !important;
}

.dashboardCalendar .p-datepicker .p-datepicker-header,
.dashboardCalendar .p-datepicker {
  border: none !important;
}

.dropdownSlideMenuPrimeng.p-slidemenu {
  top: 60px !important;
  // left: -163px!important;
}

.smallBox {
  background-image: linear-gradient(#0263ac, #388ece);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  border-radius: 5px;
  color: #fff;
  margin-bottom: 15px;
}

.smallBox i {
  font-size: 35px;
}

.smallBox h3 {
  font-size: 18px;
  margin-top: 20px;
  text-align: center;
  line-height: 1.5;
}

.newsfeedimg {
  height: 120px;

  img {
    width: 100%;
    height: 100%;
    object-fit: initial;
  }
}

.newfeedContainer {
  max-height: 280px;
  overflow-x: hidden;
  overflow-y: auto;
}

/*Card*/

label {
  color: #495057de;
  font-weight: 500;
  font-family: "Montserrat-SemiBold";
  font-size: 14px;
}

.pngPanelList p-header {
  justify-content: flex-end !important;
}

.p-paginator {
  justify-content: flex-end !important;
  border: none;
  background: transparent;
  // color: #333333;
  color: var(--base-dark-blue) !important;
  margin-top: 20px;
}

.pbtn-transparent.p-button:enabled:hover,
.pbtn-transparent {
  // padding: 4px 8px;
  background: transparent;
  border-color: transparent;
  color: #2880C2;
}

.p-datatable .p-datatable-tbody > tr > td {
  border: none;
}

.p-datatable .p-datatable-thead > tr > th {
  background: transparent;
  border: none;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background: #f4f7fe;
}

.p-paginator .p-paginator-current {
  float: left;
  position: absolute;
  left: 0;
}

.p-tag {
  font-size: 12px;
  font-weight: 400;
  padding: 2px 10px !important;
  border-radius: 3px;
}

.p-tag.p-tag-warning {
  background-color: #FFCDD2 !important;
  color: #C63737 !important;
}

.p-tag.p-tag-info {
  background-color: #ffd8b2 !important;
  color: #805b36 !important;
}

.p-tag.p-tag-success {
  background-color: #B3E5FC !important;
  color: #23547B !important;
}

.p-tag.p-tag-danger {
  background-color: rgb(189, 56, 56) !important;
  color: rgb(148, 3, 3) !important;
}

@media screen and (max-width: 991px) {
  .p-paginator .p-paginator-current {
    position: inherit;
    flex-basis: 100%;
  }
}

// p-card {
//     padding: 15px !important;
// }
.invalidfield .p-dropdown,
.invalidfield .p-dropdown .p-dropdown-trigger,
.invalidfield .p-inputnumber-button-group button,
.invalidfield .p-inputtext,
.invalidfield .p-inputtext:enabled:focus,
.invalidfield input,
.invalidfield label,
.invalidfield small {
  border-color: red !important;
  color: red !important;
}

.invalidfield .p-calendar-w-btn .p-datepicker-trigger {
  color: red !important;
  border: 1px solid red;
  border-left: 0px !important;
}

.sectioncontent {
  background: #fff;
  margin: 30px 0px 50px 0px;
  box-shadow: 0px 2px 26px #272d3b1a;
}

.container-calendar {

  width:80% !important;
  margin-left:4% !important;

}

.container-calendar-schedule {

  width:97% !important;
  margin-left:2% !important;

}


// dialogbox css
.p-dialog, .p-dialog-mask.p-component-overlay {
  pointer-events: auto;
  z-index: 810 !important;
}
p-confirmDialog .p-confirm-dialog {
  width: 50% !important;
}
p-confirmDialog .p-dialog .p-dialog-header {
  border-bottom: none;
}
p-confirmDialog .p-dialog .p-dialog-footer {
  border-top: none;
}
p-confirmDialog .p-dialog-footer .p-confirm-dialog-reject, p-confirmDialog .p-dialog-footer .p-confirm-dialog-reject:hover  {
  background: transparent;
  color: #1B7BBD;
  border-color: #1E7DBF;
  font-size: 16px;
}
p-confirmDialog .p-dialog-footer .p-confirm-dialog-accept, p-confirmDialog .p-dialog-footer .p-confirm-dialog-accept:hover  {
  background: transparent;
  color: #FFFF;
  background-color: #0263AC;
  border-color: #0263AC;
  font-size: 16px;
}
.p-dialog {
  width: 90vw !important;
  max-width: 100%;
}

.p-dialog .p-dialog-header {
  border-bottom: 1px solid #eeeeef;
  padding-left: 1rem;
}

.p-dialog .p-dialog-content {
  padding-top: 30px;
}

.p-dialog .p-dialog-footer {
  padding-top: 20px;
  border-top: 1px solid #eeeeee;
}

// end dialogbox css
.manageSubsGrid {
  width: 100%;
}
.manageSubsSelectBtn .p-selectbutton {
  text-align: right;
}
.manage-subs-btns .p-button{
  padding-left: 10px !important;
}
.manageSubsLabel  {
  white-space: break-spaces;
}
.readOnlyTitle {
  color: #495057de;
  font-family: "Montserrat-SemiBold";
  font-size: 14px;
}

.readOnlyValue {
  color: #495057de;
  font-family: "Montserrat-Regular";
  font-size: 14px;
  margin-bottom: 15px;
}

.readOnlyValue a {
  color: var(--base-light-blue) !important;
  cursor: pointer;
}

.p-input-icon-right > i.pi-search {
  cursor: pointer;
}

.sectionheading {
  padding: 0px 50px 0px 4.5rem;

}
#comm-dialog .p-dialog-title {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .sectionheading {
    padding: 0px 30px;
    text-align: center;
    margin-top: 15px;
  }
  // .p-steps.p-component {
  //   overflow-x: scroll;
  // }
  .sectionheading h1 {
    font-size: 16px !important;
  }
  .sectionheading h3 {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 480px) {
  .sectionheading {
    padding: 0px 0px;
  }
}

.p-fluid .p-calendar .p-inputtext {
  border-right: 0px !important;
}

.p-calendar-w-btn .p-datepicker-trigger {
  background-color: #0263AC;
  color: #fff !important;
  border: 1px solid #0263AC;
  border-left: 0px !important;
}

.sectionheading h1 {
  font-size: 20px;
  font-family: "Montserrat-SemiBold";
}

.sectionheading h3 {
  font-size: 16px;
  font-weight: bold;
  font-family: "Montserrat-Regular";
  color: #6e8db9;
}

.passwrodCustomized > i {
  z-index: 99;
  width: 5%;
}

.passwrodCustomized .p-inputtext {
  padding-left: 35px;
}

.cardFontSizeP {
  font-size: 23px;
  text-shadow: 0px 2px 0px #00000017;
}

.superAdminTitles {
  font-size: 18px;
  font-family: Montserrat-SemiBold;
  color: #045490;
}

.dashboard-card-text {
  height: 30px;
}

.dashboardText {
  font-size: 14px;
  font-family: 'Montserrat-Bold' !important;
  padding: 7px 0px;
  opacity: 1;
  color: #FFFFFF;
  letter-spacing: 1px;
}

// Scroll
// / width /
::-webkit-scrollbar {
  width: 3px;
  height: 8px;
}

// / Track /
::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 5px #666666;
  border-radius: 7px;
  background: rgb(104, 101, 101);
  }

  // / Handle /
  ::-webkit-scrollbar-thumb {
  background: #a0a2a3 !important;
  border-radius: 5px;
  }

  // / Handle on hover /
  ::-webkit-scrollbar-thumb:hover {
  background: #a0a2a3;
  }
::-webkit-input-placeholder { /* Edge */
opacity: 0.4;
}

:-ms-input-placeholder { /* Internet Explorer */
  opacity: 0.4;
}

::placeholder {
  opacity: 0.4;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
//file upload css
.pngFileUpload .p-fileupload .p-fileupload-buttonbar {
  background: #F4F7FE;
  border: 1px solid #F4F7FE;
}
.pngFileUpload .p-fileupload .p-fileupload-buttonbar .p-button {
  background-color: #0263AC;
  width: 170px;
}
 .pngFileUpload .p-fileupload-row .p-button.p-component.p-button-icon-only {
  width: 2rem !important;
  background-color: #0263AC;
}
//  .pngFileUpload .p-fileupload-row div img {
//   width: 100%;
//   height: 100%;
// }

.pngFileUpload .p-button:focus {
  box-shadow: none;
  }
// file upload css end
// Stepper CSS
.p-steps.p-component {
  overflow-x: auto;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  padding: 0 1rem;
  font-family: 'Montserrat-Regular';
}
 .p-steps .p-steps-item.p-highlight .p-steps-number {
  background: #359FF0 !important;
  border: 1px solid #359FF0 !important;
  }
.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
  box-shadow: none;
  }
.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  padding: 25px 20px;
  // top: -10px;
  position: relative;
  cursor: pointer;
  font-size: 26px;
  //  color: #ffff;
  font-family: 'Montserrat-SemilBold';
  }
.p-steps.p-component {
  padding: 1rem 0;
  }
  .p-steps .p-steps-item .p-menuitem-link {
    background: transparent !important;
  }
  .p-steps .p-steps-item:before {
    border-top: 3px solid #D5D5D5;
    top: 52%;
  }
  .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    background: #D5D5D5;
    color: #fff;
    border: 1px solid #D5D5D5;
  }
  //Stepper CSS end

  //Label and Button; Add Values to Dropdown
.label-and-btn {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 0 3px;
}
.addToDropdownBtn {
  padding: 5px;
  margin-bottom: 3px;
  border-radius: 8px;
  background: linear-gradient(to top, #0263AC, #388ECE);
}
.addToDropdownBtn::before {
  color: #fff;
  cursor: pointer;
}
.side-menu-delete-icon {
  filter: brightness(0) invert(1);
}
.sideMenuItemIcon {
  width: 14px;
  margin-left: 10px;
}
.painterro-div {
  height: 33rem;
}
// .ptro-wrapper .ptro-crp-el{
//   z-index : 1;
// }

.outcome_measure .p-input-icon-right > i {
  top: 51%;
}

// Body Chart
.body-chart-summary {
  background: #fff;
  box-shadow: 0px 2px 26px #272d3b3d;
  border-radius: 6px;
  padding: 20px 20px 10px 20px;
  border-top: 2px solid #51B4FF;
  }
  .body-chart-summary .carousel-btns .p-carousel .p-carousel-indicators .p-carousel-indicator button {
  height: 20px;
  width: 20px;
  border-radius: 50px;
  }
  .summary-right-col, .symptom-title-right {
  text-align: right;
  }
  .summary-right-col ul {
  padding-right: 15px;
  }
  .summary-left-col, .symptom-title-left {
  text-align: left;
  }
  .summary-left-col ul {
  padding-left: 15px;
  }
  .summary-left-col ul, .summary-right-col ul {
  margin-bottom: 5px;
  }
  .symptom-title-right, .symptom-title-left {
  font-family: 'Montserrat-SemiBold';
  color: #045490;
  font-size: 19px;margin-bottom: 0px;
  }
  .summary-left-col ul li, .summary-right-col ul li {
  color: #045490;
  font-family: 'Montserrat-Regular';
  font-size: 16px;
  }
  .summary-wrap {
  padding: 5px 0px;
  }
  .happiness-metre {
  text-align: -webkit-center;
  padding-right: 5rem;
  }
  .summary-right-col {
  direction: rtl;
  }

  .metre-btn-random {
  position: relative;
  background: #464646;
  color: #464646;
  left: 10px;
  clip-path: polygon(100% 100%, 100% 0, 10% 47%);
  box-shadow: 0px 3px 6px #00000059;
  display: none;
  }
  .metre-btn-random2 {
  position: absolute;
  right: -26px;
  top: 24%;
  background: #464646;
  color: #464646;
  clip-path: polygon(100% 100%, 100% 0, 10% 47%);
  box-shadow: 0px 3px 6px #00000059;
  }
  .percentage-value{
  position: absolute;
  right: -75px;
  top: 22%;
  font-size: 22px;
  color: #045490;

  }

  .metre-item {
  height: 40px;
  width: 50px;
  padding-left: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  font-size: 16px;
  font-family: 'Montserrat-Medium';
  color: #FFFFFF;
  position: relative;

  }
  .metre-item-second {
  padding: 0;
  }
  .metre-item:nth-child(1) {
  background-color: #99090A;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  }
  .metre-item:nth-child(2) {
  background-color: #B32A03;
  }
  .metre-item:nth-child(3) {
  background-color: #DB5B00;
  }
  .metre-item:nth-child(4) {
  background-color: #F1810A;
  }
  .metre-item:nth-child(5) {
  background-color: #FAB209;
  }
  .metre-item:nth-child(6) {
  background-color: #8EBE0A;
  }
  .metre-item:nth-child(7) {
  background-color: #6FB906;
  }
  .metre-item:nth-child(8) {
  background-color: #4C9F0B;
  }
  .metre-item:nth-child(9) {
  background-color: #198B27;
  }
  .metre-item:nth-child(10) {
  background-color: #067936;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  }
  .pain-score-bar {
  position: relative;
  height: 250px;
  background: linear-gradient(to top, #6DB525 72%, #DCDCDC 28%);
  }
  .bodychart-img {
  height: 100%;
  width: 100%;
  background: transparent;
  }
  .goals-bar {
  padding: 5px 40px;
  }
  .body-chart-headings {
  color: #045490;
  font-family: 'Montserrat-Medium';
  font-size: 22px;
  letter-spacing: 0px;
  height: fit-content;
  }
  .pain-score-top {
  text-align: center;
  }
  .metre-img img {
  width: 32px;
  margin-right: -65px;
  display: none;
  }

  .chart-wrapper {
  box-shadow: 0px 2px 26px #272d3b1a;
  background-color: white;
  }
  .goals-carousel .p-carousel .p-carousel-content .p-carousel-prev, .goals-carousel .p-carousel .p-carousel-content .p-carousel-next {
  display: none;
  }
  .carousel-btns .p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: #009AFF !important;
  opacity: 0.38;
  }
  .carousel-btns .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  opacity: 1 !important;
  }
  .p-link:focus {
  box-shadow: none;
  }

  .metre-desc {
  color: #024171;
  position: relative;
  left: 79px;
  }
  .metre-img-text {
  font-size: 16px;
  color: #024171;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  left: 7.5rem;
  width: 16rem;
  text-align: left;
  font-family: 'Montserrat-Medium';
  display: none;
  }
  .body-chart-edit-btn {
  width: 100%;
  padding: 1rem 0;
  display: flex;
  justify-content: flex-end;
  }
  .body-chart-edit-btn .p-button, .body-chart-edit-btn .p-button:enabled:hover {
  width: 92px;
  }
//


// body chart

// Payments - Credit Cards

.credit-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.credit-cards .p-checkbox .p-checkbox-box {
  border-radius: 12px;
}
.credit-cards .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #003760;
}
.credit-cards .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box, .credit-cards  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus, .credit-cards .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #536876;
}
.credit-cards .p-checkbox .p-checkbox-box.p-highlight, .credit-cards .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background: #536876;
}
.credit-cards .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: none;
}
.credit-cards img {
  width: 100%;
  height: 100%;
  padding: 1rem 0rem 1rem 1rem;
}
.credit-cards .p-field-checkbox {
  padding-right: 3rem;
}
.credit-card-btn {
  display: flex;
  justify-content: flex-end;
}

.subscriptions-payment .p-card .p-card-content {
  box-shadow: 0px 2px 26px #272d3b12;
}
.subscriptions-payment .p-card .p-card-body {
  padding: 1rem;
}
.subs-balance .chart-wrapper canvas {
  width: 100% !important;
}
//
#customPainterro-canvas {
  height: 335px !important;
}
//
// .p-menu.p-component.p-menu-overlay {
//   width: 100%;
// }
.manage-subs-icon {
  height: 22px;
  width: 21px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../images/manage-subscribtion.png');
}
.manage-subs-icon-two {
  height: 25px;
  width: 27px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../images/manage-subscribtion.png');
}
.onboarding-steps-icon {
  height: 22px;
  width: 27px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../images/onboardingStepsIcon.png');
}
.onboarding-stats-icon {
  height: 22px;
  width: 27px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../images/onboardingStatisticsIcon.png');
}
.confirm-icon {
  height: 22px;
  width: 21px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../images/confirmIcon.png');
}
.activate-icon {
  height: 22px;
  width: 22px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../images/activate.png');
}
.deactivate-icon {
  height: 22px;
  width: 22px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../images/deactivate.png');
}
//
.p-menu .p-submenu-header {
padding: 0 1rem;
}
.p-menu.p-component.p-menu-overlay {
  width: 16rem;
}
.action-btns-menu .p-menu .p-menuitem-link .p-menuitem-text, .action-btns-menu .p-menu .p-menuitem-link .p-menuitem-icon {
  color: #2880C2;
}
.action-btns-menu .p-menu .p-menuitem-link:not(.p-disabled):hover {
  background: transparent;
  color: #2880C2
}
.menu-actions.p-button, .menu-actions.p-button:enabled:hover
{
  color: #2880C2;
    background: transparent;
    border: none;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover{
  background: transparent;
}

//

.p-input-icon-right > i:last-of-type {
  top: 1.3rem;
}
@media screen and (max-width: 767px) {

  .summary-right-col {
      direction: inherit;
  }
  .body-chart-headings, .symptom-title-right, .symptom-title-left {
      text-align: center;
  }
  .summary-left-col ul li, .summary-right-col ul li {
      text-align: center;
      list-style-type: none;
  }

}

@media screen and (width:768px) {
  .summary-right-col ul {
      padding: 0px;
  }
}
@media screen and (max-width:575px) {
  .happiness-metre {
     text-align: left;
     margin-left: 1rem;
  //    padding-right: 0rem;
  }
  .metre-img-text {
      width: 4rem;
      padding: 50px 0;
      font-size: 15px;
  }
}
@media screen and (min-width:575px) and (max-width:768px) {

  .metre-img-text {
      width: 4rem;
      font-size: 12px;
  }
}
@media screen and (min-width:769px) and (max-width:1024px) {

  .metre-img-text {
      width: 8rem;
  }
}

@media screen and (max-width:600px){
  .p-toast-top-right {
    top: 55px !important;
  }
  .subs-balance-card .p-card .p-card-body,
  .subs-balance-card .p-card .p-card-content,
  .add-payment-outer-card .p-card .p-card-content,
  .add-payment-outer-card .p-card .p-card-body{
    padding: 0;
  }

  .add-payment-inner-card .p-card .p-card-content {
    padding: 1rem !important;
  }
  .subs-balance .dashboard-card {
    padding: 15px 0 0 0 !important;
  }
}
@media screen and (max-width:375px){
  .p-toast {
    width: 20rem !important;
  }
}
@media screen and (max-width:475px){
  .client-history-appointment .p-card .p-card-body, .client-history-appointment .p-tabview .p-tabview-panels {
    padding: 0rem !important;
  }
  .client-history-appointment .p-card .p-card-content {
    padding: 1rem 0rem !important;
  }
  .appointment-container-padding .container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
// Agency "Generate Company Responsive Form"
@media screen and (max-width:458px){
  .pngbtnsdiv {
    display: flex;
  }
}
@media screen and (max-width:425px){
  .pngPanelList p-header {
    justify-content: center !important;
  }
  p-confirmDialog .p-confirm-dialog {
    width: 100% !important;
  }

}
@media screen and (min-width:768px){
  .p-dialog {
    width: 60vw !important;
  }
}

.duplicate-Email{
  color: #a94442;
  padding-left: 3px;
}

.no-Item-Error{
  color: #a94442;
  padding-left: 3px;
}
.cursor-pointer{
  cursor: pointer;
}
.top-bar-svg {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(173deg) brightness(107%) contrast(104%);
}

@media print {
  .p-dialog-header-icons, .p-button {
    display: none !important;
  }
  // .p-dialog-header {
  //   border-bottom: none !important;
  // }
  .p-paginator { display: none !important; }
}

@media screen and (max-width:375px){
  .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    padding: 0;
    font-size: 18px;
  }
}
@media screen and (min-width:376px) and (max-width:425px){
  .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    padding: 10px;
    font-size: 18px;
  }
}
@media screen and (min-width:426px) and (max-width:575px){
  .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    padding: 15px;
    font-size: 20px;
  }
}
@media screen and (min-width:576px) and (max-width:767px){
  .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    padding: 20px;
    font-size: 22px;
  }
}
//Font Change on headings (25 Nov, 2021)
h1 {
  font-size: 24px !important;
}
p-header {
  font-size: 20px !important;
}
.pngTable-icons .p-button.pbtn-transparent.p-button-icon-only:first-child{
padding-left: 0 !important;
}
