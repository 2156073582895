.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: none;
}
  ///////////////
  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #ffffff;
    border-color: var(--base-dark-blue);
    color: var(--base-dark-blue) !important; 
}
.expertAnalyticsTabs .p-tabview .p-tabview-panels, .expertAnalyticsTabs .p-tabview .p-tabview-nav, .expertAnalyticsTabs .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link, .expertAnalyticsTabs .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background-color: transparent ;
}
.expertAnalyticsTabs .p-tabview .p-tabview-panels {
    padding: 0px;
}
.expertAnalyticsTabs .p-tabview .p-tabview-nav {
    border-width: 0px;
}
.expertAnalyticsTabs .p-tabview .p-tabview-nav li {
    margin-right: 5px;
}
.expertAnalyticsTabs .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
   background: transparent;
}
.cpaToolsTabs .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    // background: #ffffff !important;
    color: white !important;
    margin: 10px;
    padding: 20px;
    text-align: center;
    background: #3367AE;
    border: 0 !important;
    border-radius: 3px !important;
}
.cpaToolsTabs .p-tabview-nav {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    flex-direction: column;
    // float: left;
    // width: 300px;
  }
.cpaToolsTabs .p-tabview .p-tabview-nav li {
    background: transparent;
}  
.cpaToolsTabs .p-tabview .p-tabview-nav {
    border-width: 0 0 0 0;
}  
.cpaToolsTabs .p-tabview .p-tabview-panels {
    padding: 0rem;
}  


.cpaToolsTabs .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border-color: white;
    color: #495057DE;
    margin: 10px;
    text-align: center;
    padding: 20px;
    background: #EEEEEE;
    border: 0 !important;
    border-radius: 0px !important;
    white-space: nowrap;
    border-radius: 3px;
  }
  @media screen and (max-width: 1671px) {
    .pracPerfAnalytics .p-tabview-nav {
        overflow-x: auto;
        overflow-y: hidden;
        flex-wrap: nowrap;
        flex-direction: unset; 
    }
    .pracPerfAnalytics .p-tabview .p-tabview-nav li .p-tabview-nav-link {
        border-width: 0 0 0 0;
    }
    .pracPerfAnalytics .p-tabview-title  {
       white-space: nowrap;
    }
  }
  @media screen and (max-width: 1240px) {
    .transAnalyticsTabs .p-tabview-nav {
        overflow-x: auto;
        overflow-y: hidden;
        flex-wrap: nowrap;
        flex-direction: unset; 
    }
    .transAnalyticsTabs .p-tabview .p-tabview-nav li .p-tabview-nav-link {
        border-width: 0 0 0 0;
    }
    .transAnalyticsTabs .p-tabview-title  {
       white-space: nowrap;
    }
  }
  @media screen and (max-width: 1018px) {
    .appointmentsAnalytics .p-tabview-nav {
        overflow-x: auto;
        overflow-y: hidden;
        flex-wrap: nowrap;
        flex-direction: unset; 
    }
    .appointmentsAnalytics .p-tabview .p-tabview-nav li .p-tabview-nav-link {
        border-width: 0 0 0 0;
    }
    .appointmentsAnalytics .p-tabview-title  {
       white-space: nowrap;
    }
  }
  @media screen and (max-width: 1001px) {
    .patientAnalytics .p-tabview-nav {
        overflow-x: auto;
        overflow-y: hidden;
        flex-wrap: nowrap;
        flex-direction: unset; 
    }
    .patientAnalytics .p-tabview .p-tabview-nav li .p-tabview-nav-link {
        border-width: 0 0 0 0;
    }
    .patientAnalytics .p-tabview-title  {
       white-space: nowrap;
    }
  }

  @media screen and (max-width: 991px) {
    .cpaToolsTabs .p-tabview-nav {
        overflow: auto;
        flex-wrap: nowrap;
        flex-direction: unset;
    }
    .cpaToolsTabs .p-tabview .p-tabview-nav li {
        background: transparent;
    }
  .history-tabs .p-tabview-nav {
        overflow-x: scroll;
        overflow-y: hidden;
        flex-wrap: nowrap;
        flex-direction: row;
    }
   .history-tabs .p-tabview .p-tabview-nav li {
        background: transparent;
    }
    .history-tabs .p-tabview-title  {
        white-space: nowrap;
     }
  }
  @media screen and (max-width: 944px) {
    .referralAnalytics .p-tabview-nav {
        overflow-x: auto;
        overflow-y: hidden;
        flex-wrap: nowrap;
        flex-direction: unset; 
    }
    .referralAnalytics .p-tabview .p-tabview-nav li .p-tabview-nav-link {
        border-width: 0 0 0 0;
    }
    .referralAnalytics .p-tabview-title  {
       white-space: nowrap;
    }
  }
  @media screen and (max-width: 767px) {

    //
    .expertAnalyticsTabs .p-tabview-nav, .appointments-tabview .p-tabview-nav {
        overflow-x: auto;
        overflow-y: hidden;
        flex-wrap: nowrap;
        flex-direction: unset; 
    }
    .expertAnalyticsTabs .p-tabview .p-tabview-nav li .p-tabview-nav-link, .appointments-tabview .p-tabview .p-tabview-nav li .p-tabview-nav-link {
        border-width: 0 0 0 0;
    }
    .expertAnalyticsTabs .p-tabview-title , .appointments-tabview .p-tabview-title  {
       white-space: nowrap;
    }
  }

  @media screen and (max-width: 464px) {
    .observations-tabs .p-tabview-nav {
        overflow-x: auto;
        overflow-y: hidden;
        flex-wrap: nowrap;
        flex-direction: unset; 
    }
    .observations-tabs .p-tabview .p-tabview-nav li .p-tabview-nav-link {
        border-width: 0 0 0 0;
    }
    .observations-tabs .p-tabview-title  {
       white-space: nowrap;
    }
  }