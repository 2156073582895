.pngBtnTransparent .p-button, .pngBtnTransparent .p-button:enabled:hover {
  background: transparent;
  color: #1B7BBD;
  border-color: #1E7DBF;
  font-size: 16px;
  // width: 154px;
  // height: 41px;
}

.p-button:focus {
  box-shadow: none;
}

.pngRedBtn .p-button, .pngRedBtn .p-button:enabled:hover {
  background: transparent;
  color: #FFFF;
  background-color: red;
  border-color: red;
  font-size: 16px;
  // width: 138px;
  // height: 41px;
}

.pngBlueBtn .p-button, .pngBlueBtn .p-button:enabled:hover {
  background: transparent;
  color: #FFFF;
  background-color: #0263AC;
  border-color: #0263AC;
  font-size: 16px;
  // width: 138px;
  // height: 41px;
}

.buttonsClass {
  display: flex;
  justify-content: flex-end;
  margin-right: 16px;
}
.uploadImgBtns .p-button {
  width: 138px !important;
}
.p-selectbutton .p-button.p-highlight, .p-selectbutton .p-button.p-highlight:hover {
  background: #0263AC;
  border-color: #0263AC;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus, .p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  box-shadow: none;
}
.dashboardBackBtn {
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
  width: 100%;
}
.mine.uploadImgBtns {
  margin-top: 16px;
}
  .buttonsClass {
    justify-content: center;
    margin-right: 0px;
  }
  .pngBtnTransparent .p-button, .pngBlueBtn .p-button {
    width: 120px;
    margin-bottom: 10px;
  }
  //.btn-new {
  //  width: 100%;
  //  text-align: end;
  //}
  .comm-title-btn .p-button {
    width: 210px;
  }
.outcome_measure_btn .p-button {
  margin-bottom: 0;
}
// Styling for Menu Buttons in Agency Comp // More Onboarding
.p-menu .p-menuitem-link .p-menuitem-icon, .p-menu .p-menuitem-link .p-menuitem-text {
  color: #2880c2;
  margin-right: 0.5rem;
  font-size: 14px;
}
@media (max-width:767px){
  .dashboardBackBtn {
    justify-content: center;
    padding-right: 15px;
  }
}
// @media (min-width: 992px) and (max-width:1200px){
//   .dashboardBackBtn {
//     padding-right: 78px;
//   }
// }
